<template>
  <msg :config="config" :type='type' :promptVisible="visible" class="employ_msg" :cancelDialog="cancelDialogs" :confirmDialog='tipsConfirm' :isdeling='isdeling'>
    <div>
      提示1
    </div>
    <div>
      提示2
    </div>
      <div>
      提示3
    </div>
  </msg>
</template>

<script>
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {

    },
    data() {
      return {
        config: {
          top: '10vh',
          width: '20rem',
          center: true,
          btnTxt: ['确定','取消'],
        },
        // type:'success',
        type:'warning',
        // type:'error',
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      tipsConfirm(){
        this.cancelDialog();
      },
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>
  .firstStep {
    margin: 24px 0 12px;
  }
  .upload {
    cursor: pointer;
    color: #4f71ff;
  }
  .main-section{
    min-height: 500px;
  }
</style>
